import React, { useState } from 'react';
import { Button, LoaderOn, Modal, Space, openNotification } from 'tt-ui-lib/core';
import { useDigitalAssetsTTAPI } from '../../../../modules/tt-digital-assets-provider';
import UpdateNetModal from './UpdateNetModal';

const ServicesSettingsModal = ({ open, setOpen }) => {
  const { startBlockChainService, stopBlockChainService } = useDigitalAssetsTTAPI();

  const [loading, setLoading] = useState(false);
  const [openNetUpdateModal, setOpenNetUpdateModal] = useState(false);

  const startService = (service) => {
    setLoading(true);
    startBlockChainService(service, true)
      .then(() => {
        openNotification({
          type: 'success',
          message: 'Service successfully started',
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while starting service',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const stopService = (service) => {
    setLoading(true);
    stopBlockChainService(service, true)
      .then(() => {
        openNotification({
          type: 'success',
          message: 'Service successfully stopped',
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while stopping service',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} title="Services settings">
      <Space
        direction="vertical"
        style={{ width: '100%', paddingTop: 20, paddingBottom: 20, position: 'relative' }}
      >
        {loading && <LoaderOn />}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h6>FCE API</h6>

          <Space>
            <Button onClick={() => stopService('api')}>Stop</Button>
            <Button type="primary" onClick={() => startService('api')}>
              Start
            </Button>
          </Space>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h6>FCE PROXY</h6>

          <Space>
            <Button onClick={() => stopService('proxy')}>Stop</Button>
            <Button type="primary" onClick={() => startService('proxy')}>
              Start
            </Button>
          </Space>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h6>Nets settings</h6>

          <Space>
            <Button onClick={() => setOpenNetUpdateModal(true)}>Update net</Button>
          </Space>
        </div>
      </Space>

      <UpdateNetModal open={openNetUpdateModal} setOpen={setOpenNetUpdateModal} />
    </Modal>
  );
};

export default ServicesSettingsModal;
