import { axiosBlockchainClient, axiosSSOConfigPrivate } from '../../api';
import { API_ADMIN_URL, API_BASE_URL } from '../urls';
import axios from 'axios';
import Cookies from 'js-cookie';

export const sendBlockchainApiRequest = async (payload) =>
  axiosBlockchainClient(API_BASE_URL, payload);

export const getAuthorizedImageByLink = async (url, headers) => {
  const accessToken = Cookies.get('access_token');

  const response = await axios({
    method: 'get',
    url,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(response.data);
  });
};

export const adminUpdateNetRequest = async (payload) =>
  axios.post(`${API_ADMIN_URL}/update_net`, payload, axiosSSOConfigPrivate());
