import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';

export const openAppLoader = (opacity) => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = opacity ?? '1';
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = '0';
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod().finally(() => {
    closeAppLoader();
    const domainValue = process.env.REACT_APP_DOMAIN;
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');

    if (disconnectSocketMethod) {
      disconnectSocketMethod();
    }

    if (withRedirect) {
      window.location.href = '/dashboard/digital_assets';
    } else {
      window.location.reload();
    }
  });
};

export const showError = (msg) =>
  openNotification({
    message: msg?.message || msg,
    type: 'error',
  });
