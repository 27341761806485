/* eslint-disable no-debugger */

import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { AdminNavbar } from '../../modules/tt-navmenu';
import PageContainer from 'components/Layout/PageContainer';

import { FceUsers } from './FceUsers';
import { FceContracts } from './FceContracts';
import FceAdminPage from './FceAdmin';

import userContext from 'context/User/userContext';

import { DigitalAssetsProvider } from 'modules/tt-digital-assets-provider';

import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import { logoutUserHandler } from '../../utils/appUtils';
import { useSelectedContext } from 'context/contextSelector';
import { LOGOUT_USER } from 'api';

import styles from './AdminRoutes.module.scss';
import AuthWrapper from 'components/AuthWarpper';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
    error: {
      main: '#e53e3e',
      contrastText: '#ffe971',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const ADMIN_ROUTES_INFO = [
  {
    path: '/',
    element: <Navigate to="/admin/fce-users" />,
  },
  {
    path: '/fce-users',
    element: <FceUsers />,
    name: 'Fce Users',
  },
  {
    path: '/fce-contracts',
    element: <FceContracts />,
    name: 'Fce Contacts',
  },
  {
    path: '/fce-admin',
    element: <FceAdminPage />,
    name: 'Fce Admin',
  },
];

const AdminRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { disconnectSocket } = useSelectedContext((context) => context.messenger);

  const { user } = useSelectedContext((context) => context.user);

  const [showNavbar, setShowNavbar] = useState(false);

  const [logoutUser] = useMutation(LOGOUT_USER);

  const logOut = () => {
    logoutUserHandler(logoutUser, disconnectSocket);
  };

  const checkUserPermissions = () => {
    const accessToken = Cookies.get('access_token');

    if (user?.id) {
      if (user?.roles_list) {
        const isAdmin = JSON.parse(user.roles_list)?.find((role) => role === 'admin');
        if (isAdmin) {
          setShowNavbar(true);
        } else {
          navigate('/');
          setShowNavbar(false);
        }
      } else {
        navigate('/');
        setShowNavbar(false);
      }
    } else if (!accessToken) {
      navigate('/');
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    checkUserPermissions();
  }, [user]);

  useEffect(() => {
    if (location && user.id) {
      const accessToken = Cookies.get('access_token');

      if (user.id && !accessToken) {
        logOut();
      }
    }
  }, [location]);

  return (
    <AuthWrapper reloadAfterLogin>
      <PageContainer>
        <DigitalAssetsProvider userContextObject={userContext}>
          <ThemeProvider theme={theme}>
            <div className={styles.content} id="content-layout">
              <div className={styles.headerWrapper}>
                <AdminNavbar
                  showNavbar={showNavbar}
                  theme={theme}
                  baseUrl={process.env.REACT_APP_TT || ''}
                  baseFceUrl=""
                  baseApiUrl={process.env.REACT_APP_API || ''}
                  mode="da"
                />
              </div>

              <div className={styles.pageContentWrapper}>
                <div className={styles.pageContent}>
                  <Routes>
                    {ADMIN_ROUTES_INFO.map((routeInfo) => (
                      <Route
                        path={routeInfo.path}
                        element={routeInfo.element}
                        key={routeInfo.path}
                      />
                    ))}
                  </Routes>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </DigitalAssetsProvider>
      </PageContainer>
    </AuthWrapper>
  );
};

export default AdminRoutes;
