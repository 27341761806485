import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import userContext from 'context/User/userContext';

import Wallet from './Wallet';
import MainMenu from './MainMenu';
import MainMenuWOAuth from './MainMenuWOAuth/index';
import CommonBlock from './CommonBlock';

import styles from './styles.module.scss';
import { useDigitalAssets, useDigitalAssetsBridge } from 'modules/tt-digital-assets-provider';
import { Button, Modal } from 'tt-ui-lib/core';

const DigitalAssets = () => {
  const { user } = useContext(userContext);

  const { switchChain } = useDigitalAssets();
  const { bridges, bridge } = useDigitalAssetsBridge();

  const [showedTab, setShowedTab] = useState('explorer.transfer');
  const [forceReload, setForceReload] = useState(false);

  const [DAWallet] = useState(null);
  const [DAMenu, setDAMenu] = useState(null);
  const [DAContent, setDAContent] = useState(null);
  const [showSwitchDialog, setShowSwitchDialog] = useState(false);
  const [toNet, setToNet] = useState(NaN);
  const [updateMenu, setUpdateMenu] = useState(false);

  useEffect(() => {
    const onResize = (evnt) => {
      // eslint-disable-next-line no-debugger
      if (!DAWallet) {
        window.setTimeout(onResize);
      } else {
        // eslint-disable-next-line no-debugger
        debugger;
        console.dir(evnt);
        console.dir(DAWallet);
        console.dir(DAMenu);
        console.dir(DAContent);
      }
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const switchChainHandle = async () => {
    const brdg = bridges.find((el) => el?.id === toNet);
    if (brdg) {
      const chainId = `0x${brdg?.chain_id?.toString(16)}`;

      const settings = {
        chainId: chainId,
        chainName: brdg?.name,
        rpcUrls: [
          `http${brdg?.ssl ? 's' : ''}://${brdg?.host}${brdg?.port > 0 ? `:${brdg?.port}` : ''}${
            brdg?.path
          }`,
        ],
        nativeCurrency: {
          name: brdg?.symbol,
          symbol: brdg?.symbol,
          decimals: brdg?.digits,
        },
        blockExplorerUrls: null,
      };

      setShowSwitchDialog(false);
      return switchChain(settings).finally(() => {
        setShowedTab(`bridge.${bridge.id}`);
        setUpdateMenu(true);
      });
    }
  };

  //-------------------------------------

  useEffect(() => {
    setShowedTab('explorer.transfer');
  }, [user]);

  useEffect(() => {
    console.log(`DigitalAssets: forceReload: ${forceReload}`);
  }, [forceReload]);

  return (
    <>
      <div className={`${styles.DA} DA`}>
        {user?.id ? (
          <div className={clsx(styles.contentBox, 'contentBox')}>
            <Wallet showedTab={showedTab} setForceReload={setForceReload} />

            <MainMenu
              updateMenu={updateMenu}
              setUpdateMenu={setUpdateMenu}
              showedTab={showedTab}
              setShowedTab={setShowedTab}
              setShowSwitchDialog={setShowSwitchDialog}
              setToNet={setToNet}
            />

            <CommonBlock
              style={{ height: '100%' }}
              showedTab={showedTab}
              setShowedTab={setShowedTab}
              forceReload={forceReload}
              setForceReload={setForceReload}
              setShowSwitchDialog={setShowSwitchDialog}
              setToNet={setToNet}
            />

            <Modal
              open={showSwitchDialog}
              onClose={() => {
                setShowedTab(`bridge.${bridge.id}`);
                setUpdateMenu(true);
                setShowSwitchDialog(false);
              }}
              title="Change network"
            >
              <p style={{ margin: '10px 0' }}>To continue, change the network in metamask</p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={() => {
                    setShowedTab(`bridge.${bridge.id}`);
                    setUpdateMenu(true);
                    setShowSwitchDialog(false);
                  }}
                >
                  CANCEL
                </Button>
                <Button type="primary" onClick={() => switchChainHandle()}>
                  CHANGE
                </Button>
              </div>
            </Modal>
          </div>
        ) : (
          <>
            <MainMenuWOAuth
              ref={(el) => setDAMenu(el)}
              showedTab={showedTab}
              setShowedTab={setShowedTab}
            />
            <CommonBlock
              className={clsx(styles.WO_AUTH, 'WO_Auth')}
              style={{ height: '100%' }}
              ref={(el) => setDAContent(el)}
              showedTab={showedTab}
              setShowedTab={setShowedTab}
            />
          </>
        )}
      </div>
    </>
  );
};
export default DigitalAssets;
